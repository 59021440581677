import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import clsx from "clsx"

const query = graphql`
  query {
    allPrismicCategorie {
      nodes {
        uid
        data {
          title {
            text
          }
        }
      }
    }
  }
`

const ProjectsNav = () => {
  const { allPrismicCategorie } = useStaticQuery(query)
  // console.log(allPrismicCategorie)
  return (
    <div
      className={clsx(
        "projects-nav",
        allPrismicCategorie.nodes > 1 ? "has-multi" : ""
      )}
    >
      <ul className="x">
        {allPrismicCategorie.nodes.map((el, i) => (
          <li key={i} className="fL">
            {/* <pre>{JSON.stringify(el)}</pre> */}
            <Link to={`/projects/${el.uid}`}>{el.data.title.text}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProjectsNav
