/* eslint react/prop-types: 0, react/display-name: 0 */
import React from "react"
import Helmet from "react-helmet"
import PubSub from "pubsub-js"
import { Layout } from "./src/components/Layout"

import { linkResolver } from "./src/core/utils"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import TemplateHome from "./src/templates/page-home"
import templateProjects from "./src/templates/page-projects"
import templateProject from "./src/templates/page-project"

// import { withPrefix } from 'gatsby'
// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

// export default wrapPageElement

const _wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <script src="https://unpkg.com/pace-js@1.0.2/pace.min.js"></script>
      </Helmet>
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRIMIC_REPO_NAME,
            linkResolver: linkResolver,
            componentResolver: componentResolverFromMap({
              home: TemplateHome,
              projects: templateProjects,
              project: templateProject,
            }),
          },
        ]}
      >
        {element}
      </PrismicPreviewProvider>
    </>
  )
}

const _wrapPageElement = ({ element, props }) => {
  // console.log(Layout)
  return <Layout {...props}>{element}</Layout>
}

const _onClientEntry = () => {
  //console.log("We've started!")
  // callAnalyticsAPI()
  const isTouch = "ontouchstart" in window ? true : false

  if (isTouch) {
    document.documentElement.classList.add("touch")
  } else {
    document.documentElement.classList.add("no-touch")
  }
}

const _onRouteUpdate = ({ location, prevLocation }) => {
  // console.log(location, prevLocation)
  PubSub.publish("ROUTE_UPDATE", location)
}

export { _wrapRootElement, _wrapPageElement, _onClientEntry, _onRouteUpdate }
