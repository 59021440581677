import React, { useEffect, useMemo } from "react"
import { graphql, Link } from "gatsby"
// import { mergePrismicPreviewData } from "gatsby-source-prismic"
import SEO from "../components/seo"
import { RichText } from "prismic-reactjs"
import ImagesScroller from "../components/ImagesScroller"
import ProjectIndex from "../components/ProjectIndex"
// import VideoPlayer from "../components/ui/VideoPlayer"
import { VideoWrapper } from "../components/ui/player/index"
import ProjectCard from "../components/ProjectCard"
import Deco from "../components/Deco"

export const pageQuery = graphql`
  query Project($uid: String!, $tagUid: String!) {
    prismicProject(uid: { eq: $uid }) {
      data {
        ..._project
      }
    }
    allPrismicProject(
      filter: {
        uid: { ne: $uid }
        data: {
          # realisateur: {eq: $realisateur},
          tags: { slug: { eq: $tagUid } }
        }
      }
    ) {
      distinct(field: uid)
      nodes {
        type
        uid

        data {
          ..._projectCard
        }
      }
    }
  }
`

const PageProject = ({ data }) => {
  // const data = useMemo(() => {
  //   if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

  //   return mergePrismicPreviewData({
  //     staticData,
  //     previewData: window.__PRISMIC_PREVIEW_DATA__,
  //   })
  // }, [staticData])

  const {
    year,
    title,
    texte,
    images,
    video_url,
    video_image,
    tags,
    credits,
    client,
    client_url,
  } = data.prismicProject.data
  const related = data.allPrismicProject.nodes
  // console.log(related)

  useEffect(() => {
    _onResize()
    window.addEventListener("resize", _onResize)

    return () => window.removeEventListener("resize", _onResize)
  }, [])

  const _onResize = () => {
    const { x } = document.querySelector(".col-credits").getBoundingClientRect()
    // console.log(x)
    document.querySelector(".mwVariable").style.minWidth = x + 7.5 + "px"
    // document.documentElement.style.setProperty("--mw-variable", `${x + 7.5}px`)
  }

  return (
    <div className="page-template page-project">
      <SEO
        pageTitle={title.text}
        pageDescription={texte.text}
        // page
        pageImage={images[0].image.url}
        template={`template-project`}
        page={true}
      />

      <article className="mbL">
        <div className="hero">
          <section
            className="video-container mbM"
            // style={{
            //   aspectRatio: "16 / 9",
            // }}
          >
            {/* <pre>{JSON.stringify(video_url)}</pre> */}
            <VideoWrapper video_url={video_url} placeholder={video_image} />
          </section>
        </div>

        <div className="content">
          <Deco input={{ position: "left", max: 3 }} />
          <Deco input={{ position: "right", max: 3 }} />

          <section className="texte-container">
            <div className="container-fluid">
              <div className="texte fL mbM">{RichText.render(texte.raw)}</div>
            </div>
          </section>

          <section className="images-container psr oh">
            <div className="mbM">
              <ImagesScroller input={images} />
            </div>
          </section>

          <section className="credits-container">
            <div className="container-fluid">
              <div className="row baseline-xs">
                <div className="col-md-2 col-xs-12">
                  <div className="tar sm:tal credits">
                    client <span className="fS icon icon-arrow-e"></span>
                  </div>
                </div>
                <div className="col-md-10 col-xs-12 col-credits fL">
                  {client_url ? (
                    <a
                      href={client_url.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {client}
                    </a>
                  ) : (
                    <span>{client} </span>
                  )}
                </div>
                {/* <div className="col-md-2 col-xs-12">
                  <div className="tar sm:tal credits">
                    type <span className="fS icon icon-arrow-e"></span>
                  </div>
                </div>
                <div className="col-md-10 col-xs-12 col-credits fL">
                  brand content
                </div> */}
                <div className="col-md-2 col-xs-12">
                  <div className="tar sm:tal credits">
                    credits <span className="fS icon icon-arrow-e"></span>
                  </div>
                </div>
                <div className="col-md-10 col-xs-12 col-credits fL">
                  <div className="x xw">
                    {/* <div>Client: nom du client, </div> */}
                    <span className="item">
                      Job :{" "}
                      <span className="value tdu">
                        {tags.document.data.title.text}
                      </span>
                    </span>
                    <span className="item">
                      Year : <span className="value tdu">{year}</span>
                    </span>
                    {credits.map((credit, i) => (
                      <span className="item  " key={i}>
                        {credit.type} :{" "}
                        <span className="value tdu">{credit.value.text}</span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>

      <section className="related-projects-container">
        {related.map((project, i) => (
          // <pre key={i}>{JSON.stringify(project)}</pre>
          <ProjectCard key={i} data={project.data} metas={project} />
        ))}
      </section>

      <div className="tac ">
        <div className={`btn btn-all btn-l `}>
          <Link to="/projects/selected">
            <span className="tdu">view all</span>{" "}
            <span className="icon icon-arrow-e"></span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PageProject
