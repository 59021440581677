import React from "react"
// import Img from 'gatsby-image';
// import ImgDuotone from './ui/ImgDuotone'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImagesScroller = ({ input }) => {
  // console.log(input)
  return (
    <div className="images-scroller scroll-x">
      <div className="row">
        <div className="mwM mwVariable"></div>
        {input.map(({ image }, i) => (
          <div className="col-lg-4 col-md-6 col-xs-12" key={i}>
            {/* <Img {...image} /> */}
            <GatsbyImage image={image.gatsbyImageData} alt="David Couliau" />
            {/* <ImgDuotone image={image} color="#E74712" /> */}
          </div>
        ))}
        {/* {input.map(({image}, i) => (
          <div className="col-lg-4 col-md-6 col-xs-12" key={i}>
            <Img {...image} />
          </div>
        ))} */}
        <div className="mwM"></div>
      </div>
    </div>
  )
}

export default ImagesScroller
