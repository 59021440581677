import React, { useContext, useEffect, useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { WrapperContext } from "./Layout"
// import Burger from "./ui/Burger"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../core/utils"
import Logo from "../images/civilians-logo.inline.svg"
import ProjectsNav from "./ProjectsNav"
import clsx from "clsx"

const query = graphql`
  query {
    header: prismicHeader {
      data {
        title {
          text
        }
        description {
          richText
          text
        }
      }
      type
    }
  }
`

const Header = () => {
  const { header } = useStaticQuery(query)
  const {
    // title,
    description,
  } = header.data

  const _WrapperContext = useContext(WrapperContext)
  const { template, projectTitle, projectIndex, theme, setTheme } =
    _WrapperContext
  const isHome = template === "home"
  // const isProject = template === 'project'
  const isProjects = template === "selected" || template === "archive"
  // console.log(template, isProjects)
  // const [height, setHeight] = useState()
  const [affix, setAffix] = useState()
  const [direction, setDirection] = useState()

  let _prevScrollTop = 0

  useEffect(() => {
    window.addEventListener("scroll", _onScroll)

    return () => {
      window.removeEventListener("scroll", _onScroll)
    }
  }, [])

  const _onScroll = (e) => {
    // setAffix(window.pageYOffset > 88)
    setAffix(window.pageYOffset > 50)
    // console.log(e)
    window.pageYOffset > _prevScrollTop
      ? setDirection("down")
      : setDirection("up")

    if (window.pageYOffset === 0) {
      setDirection("")
    }

    _prevScrollTop = window.pageYOffset
  }

  const _scrollToTop = () => {
    window.scroll(0, 1)
  }

  const _toggle = () => {
    // if(theme === 'orange')
    console.log("_toggle", theme)
    setTheme(theme === "orange" ? "white" : "orange")
  }

  return (
    <header className={clsx(affix ? "is-affix" : "", direction)}>
      <div className="inner">
        <div className="x sm:xdc xac">
          <Link to="/" className="logo">
            {isHome ? (
              <h1>
                <Logo />
              </h1>
            ) : (
              <Logo />
            )}
          </Link>

          {!isHome && (
            <>
              {projectTitle && <h1 className="fL ">{projectTitle}</h1>}
              {isProjects && <ProjectsNav />}
            </>
          )}
        </div>

        {isHome && (
          <div className="description fL">
            <RichText
              render={description.richText}
              linkResolver={linkResolver}
            />
          </div>
        )}
      </div>

      <div
        className="disc curp"
        onClick={_scrollToTop}
        onMouseEnter={_toggle}
        // onMouseLeave={_toggle}
      ></div>
    </header>
  )
}

export default Header
