import React from "react"
import { graphql, Link } from "gatsby"
// import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import SEO from "../components/seo"
import { VideoWrapper } from "../components/ui/player/index"
import ProjectCard from "../components/ProjectCard"

export const pageQuery = graphql`
  query Home {
    prismicHome {
      type
      data {
        title {
          text
        }
        texte {
          text
        }
        video_url
        video_image {
          url
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            # formats: [AUTO, WEBP, AVIF]
          )
        }

        projects {
          project {
            uid
            type
            document {
              ... on PrismicProject {
                data {
                  ..._projectCard
                }
              }
            }
          }
        }
      }
    }
  }
`

const Home = ({ path, data }) => {
  const { title, texte, video_url, video_image, projects } =
    data.prismicHome.data

  return (
    <div className="page-template home">
      <SEO
        pageTitle={title.text}
        pageDescription={texte.text}
        template="template-home"
        pathname={path}
      />
      {video_url && (
        <section className="demo-reel-container mbM">
          {/* <pre>{JSON.stringify(video_url)}</pre> */}
          <VideoWrapper video_url={video_url} placeholder={video_image} />
        </section>
      )}

      <section className="projects-container">
        {projects.map(({ project }, i) => (
          <ProjectCard key={i} data={project.document.data} metas={project} />
        ))}

        <div className="tac ">
          <div className={`btn btn-all btn-l `}>
            <Link to="/projects/selected">
              <span className="tdu">view all</span>{" "}
              <span className="icon icon-arrow-e"></span>
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home
