import React from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import ImagesScroller from "./ImagesScroller"
import ProjectIndex from "./ProjectIndex"
import ProjectMore from "./ProjectMore"
import { linkResolver } from "../core/utils"
import Deco from "./Deco"

const ProjectListe = ({ data, metas }) => {
  const {
    year,
    index,
    title,
    // texte,
    tags,
  } = data

  return (
    <div className="project-liste ">
      <div className="card-header fL">
        <div className="container-fluid">
          <div className="headline">
            <Link to={linkResolver(metas)}>
              <div className="row baseline-xs">
                <div className="col-md-1 col-xs-2">
                  <ProjectIndex index={index} />
                </div>
                <div className="col-md-9 col-xs-12">
                  <span className="client fM">client</span> —{" "}
                  <span>
                    <h2 className="title ">{title.text}</h2>
                  </span>
                </div>
                <div className="col-md-2 col-xs-12 tar">
                  <div className="categorie wsnw ttl fM">
                    {tags.document.data.title.text}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectListe
