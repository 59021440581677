import React from "react"
// const civsVertical = require("../images/civs-vertical.svg")
import civs from "../images/civs-vertical.svg"

const ProjectIndex = ({ index }) => {
  const width = 5.726
  return (
    <div className="project-index ">
      <img src={civs} alt="" width="" height={21} />{" "}
      <span className="index ">{index}</span>
    </div>
  )
}

export default ProjectIndex
