import React, { useRef, useContext, useEffect } from "react"
import PubSub from "pubsub-js"
import { VideoContext } from "./index"

const idleWait = 3000

const TimelineControls = () => {
  const timelineControlsRef = useRef()
  const [video, setVideo] = useContext(VideoContext)

  let idleTimer = null,
    idleState = true

  useEffect(() => {
    // console.log(video)
    _idle()
    window.addEventListener("mousemove", _idle)

    return () => window.removeEventListener("mousemove", _idle)
  }, [video.playing])

  const _idle = () => {
    clearTimeout(idleTimer)
    // console.log(video.playing)
    if (idleState == true) {
      _showControls()
    }

    idleState = false
    if (!video.playing) return

    idleTimer = setTimeout(() => {
      console.log("time out")
      _hideControls()
      idleState = true
    }, idleWait)
  }

  const _hideControls = () => {
    if (timelineControlsRef.current)
      timelineControlsRef.current.classList.remove("show")
  }

  const _showControls = () => {
    if (timelineControlsRef.current)
      timelineControlsRef.current.classList.add("show")
  }

  const _secondsToHms = (t) => {
    const d = Number(t)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)
    return (
      (h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") +
      m +
      ":" +
      (s < 10 ? "0" : "") +
      s
    )
  }

  const _seek = (e) => {
    const percent = (e.pageX * 100) / window.innerWidth
    const { duration } = video
    const newTime = (percent * duration) / 100

    PubSub.publish("SEEK", { newTime: newTime })
  }

  const _togglePlayPause = () => {
    setVideo({
      ...video,
      playing: !video.playing,
    })
  }

  const _mute = () => {
    setVideo({
      ...video,
      mute: !video.mute,
    })
  }

  const _toggleFullScreen = () => {
    setVideo({
      ...video,
      fullscreen: !video.fullscreen,
    })
  }

  const { progress, duration, playing, mute, fullscreen } = video
  // const playPauseLabel = playing ? 'pause' : 'play'

  return progress ? (
    <div className="timeline-controls" ref={timelineControlsRef}>
      <div className="container-fluid">
        <div className="actions x xjb fL sm:fS">
          <div className="btn-group x">
            <div className="play-pause  btn" onClick={_togglePlayPause}>
              {playing ? "pause" : "play"}
            </div>
            <span>—</span>
            <div
              className={`mute  btn ${mute ? "is-active" : ""}`}
              onClick={_mute}
            >
              mute
            </div>
          </div>
          <div className="timecode" onClick={_seek}>
            {_secondsToHms(progress.playedSeconds)}—{_secondsToHms(duration)}
          </div>
          <div className="btn-group tar">
            <div
              className={`fullscreen  btn ${fullscreen ? "is-active" : ""}`}
              onClick={_toggleFullScreen}
            >
              fullscreen
            </div>
          </div>
        </div>
        <div className="timeline">
          <div
            className="timeline--played"
            style={{ width: progress.played * 100 + "%" }}
          ></div>
          {/* <div className="timeline--loaded" style={{width: (progress.loaded*100)+"%"}}></div>  */}
        </div>
      </div>
    </div>
  ) : null
}

export default TimelineControls
