import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
// import Deco from "./Deco"
import { useState, useEffect } from "react"

const WrapperContext = React.createContext()

const query = graphql`
  query {
    prismicGlobal {
      data {
        title {
          text
        }
        description {
          text
        }
        image {
          url
        }
      }
    }
  }
`

const Layout = ({
  children,
  pageContext: { template, projectTitle, projectIndex },
}) => {
  const { prismicGlobal } = useStaticQuery(query)
  // const emptyObj = {}
  const [theme, setTheme] = useState("orange")

  useEffect(() => {
    console.log(theme)
    document.body.dataset.theme = theme
    // if (document.body.classList.contains("is-orange")) {

    // }
  }, [theme])

  useEffect(() => {
    _onResize()
    window.addEventListener("resize", _onResize)

    return () => window.removeEventListener("resize", _onResize)
  }, [])

  const _onResize = () => {
    const mainArea = document.querySelector(".grid-ghost .col-md-10 .item")
    if (!mainArea) return

    const { x } = mainArea.getBoundingClientRect()

    document.documentElement.style.setProperty("--mw-variable", `${x + 7.5}px`)
  }

  return (
    <WrapperContext.Provider
      value={{
        template,
        projectTitle,
        projectIndex,
        global: prismicGlobal,
        theme,
        setTheme,
      }}
    >
      <div id="page">
        <div className="grid-ghost">
          <div className="row">
            <div className="col-md-2">
              <div className="item"></div>
            </div>
            <div className="col-md-10">
              <div className="item"></div>
            </div>
          </div>
        </div>
        <div className="sws">
          <Header />
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }
