import React, {
  useContext,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react"
import PubSub from "pubsub-js"
import ReactPlayer from "react-player"
import TimelineControls from "./TimelineControls"
import screenfull from "screenfull"
import "./index.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const initialVideoState = {
  // index: 0,
  // total: null,
  started: false,
  playing: false,
  mute: false,
  fullscreen: false,
  // progress: null,
  // duration: 0
}
const VideoContext = createContext([{}, () => {}])

const VideoWrapper = ({ video_url, placeholder }) => {
  const playerWrapper = useRef()
  const player = useRef(null)

  const [video, setVideo] = useState(initialVideoState)
  // console.log(video)

  useEffect(() => {
    // console.log("useEffect",video)
    // const [video, setVideo] = useContext(VideoContext);
    PubSub.subscribe("SEEK", _seek)
    return () => {
      PubSub.unsubscribe("SEEK", _seek)
    }
  }, [])

  if (screenfull.isEnabled) {
    useEffect(() => {
      screenfull.on("change", _onFullScreenChange)
      return () => screenfull.off("change", _onFullScreenChange)
    }, [])

    useEffect(() => {
      if (video && screenfull) {
        if (video.fullscreen) {
          screenfull.request(playerWrapper.current)
        } else {
          screenfull.exit()
        }
      }
    }, [video.fullscreen])
  }

  const _onFullScreenChange = () => {
    if (!screenfull.isFullscreen) {
      setVideo((video) => ({
        ...video,
        fullscreen: false,
      }))
    }
  }

  const _onDuration = (duration) => {
    // console.log(duration)
    setVideo((video) => ({
      ...video,
      duration: duration,
    }))
  }
  const _onReady = (event) => {
    // console.log(event)

    setVideo({
      ...video,
      progress: null,
      duration: null,
    })
  }
  const _onStart = (event) => {}
  const _onError = (event) => {}
  const _onProgress = (progress) => {
    // const _onProgress = (event) => {
    // console.log(data)
    // console.log(video)
    // setVideo({...video, progress: progress})
    setVideo((video) => ({
      ...video,
      progress: progress,
    }))
  }

  const _onEnded = (event) => {
    // console.log(video)
    player.current.seekTo(0)
    return
    // const newIndex = (video.index + 1) % 0;
    // console.log("_onEnded",newIndex)
    setVideo({
      ...video,
      index: newIndex,
      progress: null,
      duration: null,
    })
  }
  // const _mute = (event) => {}
  const _seek = (event, payload) => {
    // console.log(payload)
    const { newTime } = payload
    player.current.seekTo(newTime)
    // console.log(reactPlayer)
    // const { duration } = this.state
    // const newTime = (d.percent * duration) / 100
    // // console.log(d.percent, duration, newTime)
    // this.refs.reactPlayer.seekTo(newTime)
  }

  const _togglePlayPause = () => {
    setVideo({
      ...video,
      playing: !video.playing,
      started: true,
    })
  }

  const config = {
    youtube: {
      playerVars: {
        showinfo: 0,
        iv_load_policy: 3,
        cc_load_policy: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        origin: "https://civilians.netlify.app/",
      },
    },
    vimeo: {
      title: "false",
    },
  }
  // console.log(video)
  return (
    <VideoContext.Provider value={[video, setVideo]}>
      <div id="video-wrapper" ref={playerWrapper}>
        {placeholder && video.started && (
          <ReactPlayer
            // ref="reactPlayer"
            ref={player}
            className="react-player"
            // url={"https://vimeo.com/259878665"}
            url={video_url}
            width="100%"
            height="100%"
            playing={video.playing}
            // controls
            playsinline
            config={config}
            muted={video.mute}
            volume={1}
            onDuration={_onDuration}
            onReady={_onReady}
            onStart={_onStart}
            onProgress={_onProgress}
            onEnded={_onEnded}
            onError={_onError}
          />
        )}

        {video.progress && <TimelineControls />}
        {placeholder && !video.started && (
          <div className="placeholder">
            <GatsbyImage
              image={placeholder.gatsbyImageData}
              alt="video placeholder"
            />
          </div>
        )}
        <div className="play-pause-overlay" onClick={_togglePlayPause}></div>
      </div>
    </VideoContext.Provider>
  )
}

export { VideoContext, VideoWrapper }
