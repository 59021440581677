import React, { useEffect, useRef, useState } from 'react';

const Deco = ({input}) => {
  const [arr, setArr] = useState([])
  const icons = [
    'icon-frise-1',
    'icon-a',
    'icon-lines-civs-dots',
    'icon-lines-dots-1',
    'icon-11a',
    'icon-24a',
    'icon-arrow-bidirection',
    // 'icon-asterix',
    'icon-circle-rect',
    'icon-civilians-paris-xov',
    'icon-civilians-star-7',
    'icon-civiliansparis-cartouche',
    'icon-civs-2',
    'icon-civs-3-dots-cusros',
    'icon-civs-16',
    // 'icon-civs-civilians',
    'icon-civs-dots-civs-dots',
    'icon-civs',
    'icon-forme-1',
    'icon-frise-2',
    // 'icon-hand-s',
    'icon-start-mark-jap',
    'icon-start',
    'icon-stop',
  ]

  const { 
    max, 
    position 
  } = input
  
  // const arr = shuffle.slice(0, max)

  const decoRef = useRef()
  useEffect(() => {
    _shuffle()
    // _onResize()
    // document.addEventListener("resize", _onResize)

    const token = PubSub.subscribe("ROUTE_UPDATE", (e,d) => {
      _shuffle()
    })
    return () => {
      PubSub.unsubscribe(token)
      // document.removeEventListener("resize", _onResize)
    }
  }, [])

  const _shuffle = () => {
    // _onResize()
    const shuffle = icons.sort(() => .5 - Math.random());
    const _arr = shuffle.slice(0, max)
    // console.log(_arr)
    // if(input === 'left')_arr.unshift('icon-start');
    // if(input === 'right')_arr.push('icon-stop')
    setArr(_arr)
  }

  const _onResize = () => {
    // const mainHeight = document.querySelector("main").getBoundingClientRect().height
    // decoRef.current.style.height = mainHeight+"px"
  }
 
  return (
    <div className={`deco ${position} len-${max}`} ref={decoRef}>
      <div className="x xdc xac xjb h100">
      {arr.map((icon, i) => (
        <div className="container-icon" key={i}>
          <span className={`icon ${icon}`}></span>
          {/* <img key={i} src={`/${svg}`} alt="" height="10" width="" /> */}
        </div>
      ))}
      </div>
    </div>
  );
};

export default Deco;
