import React, { useMemo, useEffect } from "react"
import { graphql } from "gatsby"
// import { mergePrismicPreviewData } from "gatsby-source-prismic"
import SEO from "../components/seo"
// import { VideoWrapper } from "../components/ui/player/index"
import ProjectCard from "../components/ProjectCard"
import ProjectListe from "../components/ProjectListe"
import Deco from "../components/Deco"

export const pageQuery = graphql`
  query Projects($uid: String!) {
    # allPrismicProject {
    #   nodes {
    #     uid
    #     type
    #     data{
    #       ..._projectCard
    #     }
    #   }
    # }
    allPrismicProject(
      filter: { data: { categorie: { uid: { eq: $uid } } } }
      sort: { fields: data___index, order: DESC }
    ) {
      nodes {
        uid
        type
        lang
        data {
          ..._projectCard
        }
      }
    }
  }
`
/*
cat selected or archive
*/
const Projects = ({ path, pageContext: { template }, data }) => {
  const projects = data.allPrismicProject.nodes

  useEffect(() => {}, [])

  return (
    <div className="page-template projects">
      <SEO
        pageTitle={"Projects"}
        pageDescription={"texte.text"}
        template="template-projects"
        page
        pathname={path}
      />

      <section className="projects-container">
        {template === "selected" && (
          <div className="selected">
            {projects.map((project, i) => (
              <ProjectCard key={i} data={project.data} metas={project} />
            ))}
          </div>
        )}
        {template === "archive" && (
          <div className="archive">
            <Deco input={{ position: "left", max: 2 }} />
            <Deco input={{ position: "right", max: 2 }} />
            <div className="inner">
              {projects.map((project, i) => (
                <ProjectListe key={i} data={project.data} metas={project} />
              ))}
              {projects.map((project, i) => (
                <ProjectListe key={i} data={project.data} metas={project} />
              ))}
              {projects.map((project, i) => (
                <ProjectListe key={i} data={project.data} metas={project} />
              ))}
            </div>
          </div>
        )}
      </section>
    </div>
  )
}

export default Projects
