import React from 'react';
import { Link } from 'gatsby'
import {linkResolver} from '../core/utils'

const ProjectMore = ({metas, className}) => {

  return (
    <div className={`btn btn-more ${className}`}>
      <Link to={linkResolver(metas)} ><span className="tdu">read more</span> <span className="icon icon-arrow-e"></span></Link>
    </div>
  );
};

export default ProjectMore;