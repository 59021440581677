import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import Logo from "../images/civilians-logo-icone.inline.svg"
import { GatsbyImage } from "gatsby-plugin-image"
// import Deco from './Deco'
// import { RichText } from "prismic-reactjs"

const query = graphql`
  query {
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }

    footer: prismicFooter {
      data {
        about {
          richText
        }
        clients {
          richText
        }
        contacts {
          key
          texte {
            richText
          }
        }
        social {
          image {
            gatsbyImageData(
              width: 30
              placeholder: BLURRED
              # formats: [AUTO, WEBP, AVIF]
            )
            url
          }
          label {
            text
          }
          url {
            url
          }
        }
      }
    }
  }
`

const Footer = () => {
  const { site, footer } = useStaticQuery(query)
  const { about, clients, contacts, social } = footer.data
  // console.log(site)

  const footerRef = useRef()

  // useEffect(() => {
  //   _onResize()
  //   document.addEventListener("resize", _onResize)

  //   return () => document.removeEventListener("resize", _onResize)
  // }, [])

  // const _onResize = () => {
  //   const {height} = footerRef.current.getBoundingClientRect()
  //   // console.log(x)
  //   document.querySelector("main").style.marginBottom = height+"px"
  // }

  return (
    <footer ref={footerRef} className="scroll-y">
      {/* <Deco input={{position: 'left', max: 3}} />
      <Deco input={{position: 'right', max: 3}} /> */}

      <div className="container-fluid">
        <div className="disc"></div>

        <div className="row">
          <div className="col-md-6 col-xs-12">
            <div className="about mbM">
              <h2>Infos</h2>
              <div className="texte">
                {/* {RichText.render(about.raw)} */}
                <RichText render={about.richText} />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xs-12 h100">
            <div className="col-right h100">
              <div className="contacts mbM">
                {contacts.map((contact, i) => (
                  <div className={`contact-item x is-${contact.key}`} key={i}>
                    <div className="key">{contact.key}</div>
                    <div className="value fL">
                      {/* {RichText.render(contact.texte.raw)} */}
                      <RichText render={contact.texte.richText} />
                    </div>
                  </div>
                ))}
              </div>
              <div className="social">
                <div className={`x is-s`}>
                  <div className="key">s</div>
                  {social.map((item, i) => (
                    <div className="social-item fL" key={i}>
                      <a
                        href={item.url.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <GatsbyImage
                          image={item.image.gatsbyImageData}
                          alt={item.label.text}
                        />
                        {/* <pre>{JSON.stringify(item.image)}</pre> */}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row bottom-xs">
          <div className="col-md-6 col-xs-12">
            <Logo />
          </div>

          <div className="col-md-6 col-xs-12">
            <div className="colophon texte mbS">
              <div>Glad you scrolled all the way ☺.</div>
              <p></p>
              <div>Font Trenda® </div>
              <p>
                Website designed by{" "}
                <a href="ahmedghazi.com" target="_blank">
                  Ahmed Ghazi
                </a>
                , coded by{" "}
                <a href="ahmedghazi.com" target="_blank">
                  Ahmed Ghazi
                </a>{" "}
                from Paris, France.
              </p>
            </div>
            <div className="x xjb">
              <div className="copy">Copyright © Civilians, MMXIX.</div>
              <div className="tar">updated: {site.buildTime}</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
