import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import TextTruncate from "react-text-truncate"
import ImagesScroller from "./ImagesScroller"
import ProjectIndex from "./ProjectIndex"
import ProjectMore from "./ProjectMore"
import { linkResolver } from "../core/utils"
import Deco from "./Deco"

const ProjectCard = ({ data, metas }) => {
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    const _isMobile = window.innerWidth <= 1024 && "ontouchstart" in window
    setIsMobile(_isMobile)
  }, [])

  const { year, index, title, texte, tags, images, client } = data

  const _getLength = () => (isMobile ? 70 : 200)
  const _truncate = (input) =>
    input.length > _getLength()
      ? `${input.substring(0, _getLength())}...`
      : input

  return (
    <div className="project-card mbL">
      <div className="card-header fL">
        <div className="container-fluid">
          <div className="headline">
            <Link to={linkResolver(metas)}>
              <span className="disc "></span>
              <div className="x xjb">
                <ul className="x">
                  <li>
                    <ProjectIndex index={index} />
                  </li>
                  <li>
                    <h2 className="">{title.text}</h2>
                  </li>
                </ul>

                <div className="categorie wsnw  ">
                  <div className="x">
                    {client && <span className="wsnw">{client}</span>}
                    {client && <span className="sep-dash"> — </span>}
                    <span className="ttl">{tags.document.data.title.text}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="texte fM">
            {/* <TextTruncate
              line={2}
              element="p"
              truncateText="…"
              text={texte.text}
              textTruncateChild={<ProjectMore metas={metas} className="btn-m" />}
            /> */}
            {_truncate(texte.text)}{" "}
            <ProjectMore metas={metas} className="btn-m" />
          </div>
        </div>
      </div>

      <div className="card-body">
        <Deco input={{ position: "left", max: 2 }} />
        <Deco input={{ position: "right", max: 2 }} />
        <Link to={linkResolver(metas)}>
          <ImagesScroller input={images} />
        </Link>
      </div>
    </div>
  )
}

export default ProjectCard
